import React from "react";

export default function useTopOfRoot(dependencies) {
  React.useEffect(() => {
    const root = document.getElementById("root");
    if (root.scrollTop !== 0) {
      root.scrollTop = 0;
    }
  }, [...dependencies]);
}
