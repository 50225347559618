import React from "react";

export default function useScrollToHidden(offset, isWindow) {
  const [hidden, setHidden] = React.useState(false);
  let scrollYState = React.useRef(0);

  const handleScroll = (e) => {
    const scrollYAfter = e.target.scrollTop;
    if (scrollYAfter > 0) {
      if (scrollYState.current + offset < scrollYAfter) {
        setHidden(true);
        scrollYState.current = scrollYAfter;
      } else if (scrollYState.current - offset > scrollYAfter) {
        setHidden(false);
        scrollYState.current = scrollYAfter;
      }
    }
  };
  if (isWindow) {
    React.useEffect(() => {
      document
        .getElementById("root")
        .addEventListener("scroll", (e) => handleScroll(e));
      return () => {
        document
          .getElementById("root")
          .removeEventListener("scroll", (e) => handleScroll(e));
      };
    }, []);
    return hidden;
  }

  return [hidden, handleScroll];
}
