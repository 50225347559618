import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import reducer from "../reducers";
import channels from "./slices/channelsSlice";
import posts from "./slices/postsSlice";
import user from "./slices/userSlice";

export default configureStore({
  reducer: {
    reducer,
    channels,
    posts,
    user,
  },
  middleware: [thunk],
});
