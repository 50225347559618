import React from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

const border = 2;

const SwitcherComp = styled("div")(({ theme, width }) => ({
  display: "flex",
  position: "relative",
  borderRadius: "50px",
  background: theme.palette.primary.main,
  alignItems: "center",
  padding: "15px 0px",
  width: `${width}px`,
  height: "fit-content",
  cursor: "pointer",
  userSelect: "none",
}));

const TextComp = styled(Typography)(({ theme, attributes }) => ({
  fontSize: "12px",
  textAlign: "center",
  width: "50%",
  position: "absolute",
  zIndex: 5,
  color: attributes.isActive
    ? theme.palette.primary.main
    : theme.palette.secondary.light,
  left:
    attributes.side === "left"
      ? `${border}px`
      : `${attributes.width / 2 - border}px`,
}));

const SwitchInp = styled("input")(({ theme, width }) => ({
  height: "26px",
  width: "50%",
  position: "absolute",
  background: theme.palette.secondary.light,
  color: theme.palette.secondary.light,
  border: "none",
  borderRadius: "50px",
  left: `${border}px`,
  padding: 0,
  margin: 0,
  appearance: "none",
  outline: "none",
  transition: "all 100ms",
  "&:checked": {
    left: `${width / 2 - border}px`,
  },
}));

export default function Switcher({ first, second, onChange, checked, width }) {
  const onChangeSwitch = () => {
    onChange();
  };
  return (
    <SwitcherComp width={width} onClick={onChangeSwitch}>
      <SwitchInp
        width={width}
        checked={checked}
        type={"checkbox"}
        readOnly
      ></SwitchInp>
      <TextComp
        attributes={{ width, isActive: !checked, side: "left" }}
        className={"left"}
      >
        {first}
      </TextComp>
      <TextComp
        attributes={{ width, isActive: checked, side: "right" }}
        className={"right"}
      >
        {second}
      </TextComp>
    </SwitcherComp>
  );
}
