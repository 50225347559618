import React from "react";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

const ButtonMain = styled(Button)(({ theme }) => ({
  borderRadius: "30px",
  marginTop: "20px",
  padding: "8px 30px",
  fontSize: "18px",
  textTransform: "capitalize",
  // border: "2px solid rgb(255,255,255,0)",
  "&:hover": {
    color: theme.palette.secondary.light,
    // border: "2px solid rgb(255,255,255,0)",
  },
  "&:disabled": {
    background: theme.palette.primary.main,
    color: theme.palette.secondary.light,
    opacity: 0.7,
  },
}));

function ButtonComp({ disabled, name, onClick, style }) {
  return (
    <ButtonMain
      disabled={disabled}
      size="small"
      variant={"contained"}
      onClick={() => onClick()}
      sx={style && style}
    >
      {name}
    </ButtonMain>
  );
}

export default ButtonComp;
