import ButtonComp from "./ButtonComp";
import React from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {$subscriptions} from "../redux/slices/channelsSlice";
import {useDispatch, useSelector} from "react-redux";
import {doneOnBoarding, fetchOnboardingPass} from "../redux/slices/userSlice";
import {postSubscriptions} from "../api/channels.api";
import {resetData} from "../redux/slices/postsSlice";
import {styled} from "@mui/material/styles";
import {Box, MobileStepper} from "@mui/material";
import {postsConstants} from "../constants/posts.constants";

const Pagination = styled(MobileStepper)(() => ({
    maxWidth: 400,
    margin: "0 10px",
    padding: "0",
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    "& .MuiMobileStepper-dot": {
        margin: "0 8px",
    },
}));


export default function OnboardingButton () {
    const location = useLocation()
    const subscriptions = useSelector($subscriptions)
    const [skip, setSkip] = React.useState(true);
    const navigate = useNavigate();
    const nextStepPage = "/onboarding/step-two";
    const dispatch = useDispatch()

    React.useEffect(() => {
        if(subscriptions.length !== 0) {
            setSkip(false)
        } else {
            setSkip(true)
        }
    }, [subscriptions]);

    const onClickPassedOnboading = async () => {
        await dispatch(fetchOnboardingPass())
        navigate(postsConstants.HOME_PAGE)
    }

    const onClickSaveSubscriptions = async () => {
        const channelIds = subscriptions.map((item) => item.id);
        await dispatch(fetchOnboardingPass())
        dispatch(doneOnBoarding());
        const postSubs = async () => {
            try {
                const response = await postSubscriptions({ channel_ids: channelIds });
                if (response.data.success) {
                    dispatch(resetData());
                    navigate("/");
                }
            } catch (err) {
                console.log(err);
            }
        };
        postSubs();
    };

    return location.pathname.includes("one") ? (
                <Pagination
                    variant="dots"
                    steps={2}
                    position="static"
                    activeStep={0}
                    nextButton={
                        <ButtonComp
                            name={"Далее"}
                            onClick={() => navigate(nextStepPage)}
                        />
                    }
                />
            ) : (
                <ButtonComp
                    name={skip ? "Пропустить" : "Старт" }
                    onClick={skip ? onClickPassedOnboading : onClickSaveSubscriptions}
                />
            )
}