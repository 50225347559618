import ButtonComp from "./ButtonComp";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    $status,
    $subscriptions,
} from "../redux/slices/channelsSlice";
import {useTheme} from "@mui/material/styles";
import useDark from "../hooks/useDark";
import {useNavigate} from "react-router-dom";
import {postsConstants} from "../constants/posts.constants";
import _ from "lodash";
import {resetData} from "../redux/slices/postsSlice";
import {doneOnBoarding} from "../redux/slices/userSlice";
import {postSubscriptions} from "../api/channels.api";


export default function SettingsFixedButton () {
    const [isChangeSub, setIsChangeSub] = React.useState(false);
    const [firstSubs, setFirstSubs] = React.useState([]);
    const subscriptions = useSelector($subscriptions);
    const status = useSelector($status);
    const theme = useTheme();
    const dark = useDark();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { HOME_PAGE } = postsConstants;

    React.useEffect(() => {
        if (status === "success") {
            setFirstSubs(subscriptions);
        }
    }, [status]);

    React.useEffect(() => {
        const subsIdsSort =
            subscriptions.length !== 0
                ? _.sortBy(subscriptions, (o) => o.id).map((item) => item.id)
                : [];
        const firstSubsIdsSort = _.sortBy(firstSubs, (o) => o.id).map(
            (item) => item.id
        );

        if (_.isEqual(subsIdsSort, firstSubsIdsSort)) {
            setIsChangeSub(false);
        } else {
            setIsChangeSub(true);
        }
    }, [subscriptions, firstSubs]);

    const onClickClose = () => {
        navigate(HOME_PAGE);
        dispatch(resetData());
    };

    const onClickSaveChannels = () => {
        const channelIds = subscriptions.map((item) => item.id);
        dispatch(doneOnBoarding());
        const postSubs = async () => {
            try {
                const response = await postSubscriptions({ channel_ids: channelIds });
                if (response.data.success) {
                    navigate(HOME_PAGE);
                    dispatch(resetData());
                }
            } catch (err) {
                console.log(err);
            }
        };
        postSubs();
    };

    return  !isChangeSub ? (
        <ButtonComp
            name={"Закрыть"}
            onClick={onClickClose}
            style={{
                color: theme.palette.text.primary,
                padding: "8px 0px",
                width: "130px",
                backdropFilter: "blur(20px)",
                boxShadow:
                    "0px 1px 3px -2px rgba(0,0,0,0.1), 0px 1px 4px 0px rgba(0,0,0,0.14), 0px 2px 8px 0px rgba(0,0,0,0.1)",
                background: dark ? "rgb(0,0,0, 0.4)" : "rgb(250,250,250, 0.5)",
                border: dark
                    ? `0.5px solid rgb(250,250,250, 0.5)`
                    : "0.5px solid rgb(250,250,250, 0)",
            }}
        />
    ) : (
        <ButtonComp
            name={"Сохранить"}
            onClick={onClickSaveChannels}
            style={{
                padding: "8px 0px",
                width: "130px",
                border: `0.5 solid ${theme.palette.primary.main}`,
            }}
        />
    )
}