import { userConstants } from '../constants'

const initialState = {
  user: {}
}

export default function userReducer(state = initialState, action) {
  switch(action.type) {
    case userConstants.AUTH:
      return { ...initialState, user: action.payload }
    default:
      return initialState
  }
}
