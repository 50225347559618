import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  $firstRender,
  $isEnd,
  $order,
  $page,
  $posts,
  $scrollY,
  $status,
  changeOrder,
  fetchPosts,
  incPage,
  onChangeFirstRender,
  resetData,
} from "../redux/slices/postsSlice";
import Box from "@mui/material/Box";
import ListOfPosts from "../components/Common/ListOfPosts";
import Loader from "../components/Common/Loader";
import { useLocation, useNavigate, Outlet, useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import EmptyPosts from "../components/Common/EmptyPosts";
import SettingsButton from "../components/SettingsButton";
import TopMenu from "../components/Common/TopMenu";
import Error from "../components/Common/Error";
import Switcher from "../components/Switcher";
import { postsConstants } from "../constants/posts.constants";
import {$user, fetchOnboardingPass} from "../redux/slices/userSlice";
import {postConstants} from "../constants";
import FixedBottomBlock from "../components/FixedBottomBlock";
import ButtonComp from "../components/ButtonComp";
import {functions} from "../functions";
import usePeriod from "../hooks/usePeriod";

const menuSavings = [
  { title: "Сохраненки", link: "/bookmarks" },
  { title: "Просмотренные", link: "/viewed" },
];

const menuFeed = [
  { title: "Подписки", link: "/subscriptions" },
  { title: "Все каналы", link: "/posts" },
];

export default function PostsPage({ title, emptyMessage }) {
  const [period, setPeriod] = React.useState(30);
  const firstRender = useSelector($firstRender);
  const posts = useSelector($posts);
  const status = useSelector($status);
  const isEnd = useSelector($isEnd);
  const scrollY = useSelector($scrollY);
  const page = useSelector($page);
  const order = useSelector($order);
  const user = useSelector($user);
  const [checked, setChecked] = React.useState(user.feed_order !== "smart");
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const searchParams = new URLSearchParams(location.search);
  const from = searchParams.get('from');
  const to = searchParams.get('to');
  const ref = React.useRef();
  const currentRoute = location.pathname
  const bestRoute = currentRoute === postsConstants.BEST_OF_DATES
  const periodTitle = usePeriod(from, to)
  const newTitle = bestRoute ? title + " " + periodTitle : title
  const feedPages = ["/posts", "/subscriptions"];

  const type = currentRoute.split("/")[1];

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(changeOrder(user.feed_order));
  }, []);

  React.useLayoutEffect(() => {
    const root = document.getElementById("root");
      root.scrollTop = 0;
      const props = {
        page,
        period,
        action: "pageLoad",
        type,
        order,
        from,
        to
      };
      dispatch(fetchPosts(props));

    if (!params) {
      setShow(false);
    }
  }, [title, order]);

  React.useEffect(() => {
    if (order === "by_date") {
      setChecked(true);
    }
  }, []);

  const handleLoadMore = () => {
    const props = {
      page: page + 1,
      period,
      action: "add",
      type,
      order,
      from,
      to
    };
    dispatch(fetchPosts(props));
    dispatch(incPage());
  };

  const onChangePeriod = (e) => {
    setPeriod(e.target.value);
    dispatch(onChangeFirstRender({ data: true }));
  };

  const onChangeSwitch = () => {
    setChecked(!checked);
    dispatch(changeOrder());
    dispatch(resetData());
  };

  let listOfPosts =
    posts.length > 0 ? (
      <Box ref={ref} sx={{ marginBottom: "64px" }}>
        <ListOfPosts
          posts={posts}
          backUrl="/subscriptions"
          header={title}
          loadMore={handleLoadMore}
          paginationEnabled={true}
          onChangePeriod={onChangePeriod}
          status={status}
          isEnd={isEnd}
          page={page}
          location={location}
        />
      </Box>
    ) : (
      <EmptyPosts>
        <Typography
          variant={"caption"}
          sx={{ display: "block", fontSize: "18px", textAlign: "center" }}
        >
          {emptyMessage}
          {currentRoute === "/subscriptions" && (
            <>
              {" "}
              <SettingsButton onClick={() => navigate("/settings")} />
            </>
          )}
        </Typography>
      </EmptyPosts>
    );

  let component =
    status === "loading" && firstRender ? (
      <Loader />
    ) : status === "success" && firstRender ? (
      { listOfPosts }
    ) : !firstRender ? (
      listOfPosts
    ) : (
      <Error />
    );


  const topMenu = currentRoute !== postsConstants.BEST_OF_DATES ?
      <TopMenu
          menu={
            feedPages.find((item) => currentRoute.includes(item))
                ? menuFeed
                : menuSavings
          }
          showSettings={feedPages.find((item) =>
              currentRoute.includes(item)
          )}
          location={location}
      /> : null

  const onClickBackToSubscriptions = async () => {
    await dispatch(fetchOnboardingPass())
    dispatch(resetData())
    navigate(postsConstants.HOME_PAGE)
  }

  const fixedButtonBlock = currentRoute === postsConstants.BEST_OF_DATES ?
      <FixedBottomBlock>
        <ButtonComp name={"На главную"} onClick={onClickBackToSubscriptions}/>
      </FixedBottomBlock> : null


  return (
    <>
      {topMenu}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "16px 16px 0",
        }}
      >
        <Typography variant="h6" component="h2">
          {newTitle}
        </Typography>
        {currentRoute.includes(postsConstants.HOME_PAGE) && (
          <Switcher
            first={"умная"}
            second={"по дате"}
            onChange={onChangeSwitch}
            checked={checked}
            width={120}
          />
        )}
      </Box>
      {component}
      {fixedButtonBlock}
      <Outlet />
    </>
  );
}
