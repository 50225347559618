import React from "react";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import Channels from "../../components/Common/Channels";
import { useDispatch, useSelector } from "react-redux";
import {
  $channels,
  $filteredChannels,
  $status,
  changeSubscriptions,
} from "../../redux/slices/channelsSlice";
import StatusResponse from "../../components/StatusResponse";
import SearchChannels from "../../components/Common/SearchChannels";
import GetChannels from "../../components/Common/GetChannels";

function OnBoardingPageTwo() {
  const channels = useSelector($channels);
  const filteredChannels = useSelector($filteredChannels);
  const status = useSelector($status);
  const dispatch = useDispatch();

  const onClickChangeSubscriptions = (chosenChannel) => {
    dispatch(changeSubscriptions({ chosenChannel }));
  };

  return (
    <GetChannels page={"onboarding"}>
      <Box padding={"8px 16px"}>
        <Typography variant={"h5"} component={"h1"} textAlign={"center"}>
          Выбери каналы для подписки
        </Typography>
        <SearchChannels channels={channels} />
        <StatusResponse status={status}>
          <Channels
            items={filteredChannels}
            onClickChangeSubscriptions={onClickChangeSubscriptions}
          />
        </StatusResponse>
      </Box>
    </GetChannels>
  );
}
export default OnBoardingPageTwo;
