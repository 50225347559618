import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Divider from "@mui/material/Divider";
import Avatar from "@mui/material/Avatar";
import ButtonBase from "@mui/material/ButtonBase";
import Typography from "@mui/material/Typography";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { functions } from "../../functions";
import { styled } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import { readPost, saveScroll } from "../../redux/slices/postsSlice";
import ScrollPagination from "../ScrollPagination";
import { postsConstants } from "../../constants/posts.constants";
import { regexConstants } from "../../constants/regex.constants";

const ListPosts = styled(ListItem)(({ attributes }) => ({
  overflow: "hidden",
  opacity: attributes.isRead ? 0.6 : 1,
  marginTop: "5px",
}));

const DescPost = styled(Typography)(() => ({
  margin: 0,
  fontSize: "1rem",
  fontWeight: "normal",
  display: "-webkit-box",
  lineHeight: 1.3,
  WebkitLineClamp: "2",
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis",
  maxWidth: "100%",
  opacity: 0.8,
  marginBottom: "5px",
}));

const Title = styled(Typography)(() => ({
  fontSize: "1rem",
  marginBottom: "2px",
  paddingRight: "5px",
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: "1",
  overflow: "hidden",
  textOverflow: "ellipsis",
  opacity: 0.8,
  maxWidth: "80%",
}));

export default function ListOfPosts({
  posts,
  loadMore,
  status,
  isEnd,
  page,
  location,
}) {
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fullURL = location.pathname + (location.search !== "?undefined" ? location.search : "") ;
  const postsRef = React.useRef([]);
  const readPages = postsConstants.FEED_PAGES;


  React.useEffect(() => {
    if (status === "success") {
      setLoading(false);
    }
  }, [status]);

  React.useEffect(() => {
    const { PER } = postsConstants;
    const numberOfPostToStartResponse = 15;
    const numberOfPost = PER * page - (PER - numberOfPostToStartResponse);
    if (posts.length > numberOfPost) {
      const observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setLoading(true);
          loadMore();
          observer.disconnect();
        }
      });
      postsRef.current[numberOfPost] !== null &&
        observer.observe(postsRef.current[numberOfPost]);
      return () => {
        observer.disconnect();
      };
    }
  }, [posts]);

  const onClickNavigate = (i, postId) => {
    const linkMemo = location.pathname.replace("/" + params.id, "");
    if (readPages.find((item) => item.includes(linkMemo))) {
      dispatch(readPost({ i }));
    }
    navigate(`${linkMemo}/${postId}${fullURL.includes("?") ? "?" + fullURL.split("?")[1] : ""}`);
    const scrollY = window.scrollY;
    dispatch(saveScroll({ scrollY }));
  };

  const postsMarkup = React.useMemo(() => {
    const { HTML_TAGS_REGEX, URL_REGEX } = regexConstants;
    return posts.map((post, i) => {
      let rawHTML = post.text
        .replace(HTML_TAGS_REGEX, "")
        .replaceAll("\n\n", " ");
      const matchURL = rawHTML.match(URL_REGEX);
      if (matchURL) {
        const parseURLs = matchURL.map((item) => {
          const parseURL = new URL(item);
          return parseURL.hostname;
        });
        matchURL.forEach((item, i) => {
          rawHTML = rawHTML.replace(item, parseURLs[i]);
        });
      }

      const shortTitleChannel = post.channel.title;
      const isRead =
        readPages.find((item) => location.pathname.includes(item)) &&
        post.readed;
      const dateStr = functions.getDay(post.date);
      return (
        <Box
          data-id={post.id}
          ref={(el) => (postsRef.current[i] = el)}
          key={post.id}
        >
          <ButtonBase
            sx={{ width: "100vw" }}
            onClick={() => onClickNavigate(i, post.id)}
          >
            <ListPosts attributes={{ isRead }} alignItems="flex-start">
              <ListItemAvatar sx={{ marginTop: "5px" }}>
                <Avatar
                  alt="Remy Sharp"
                  src={post.channel.image_100}
                  sx={{ marginTop: "0" }}
                />
              </ListItemAvatar>
              <Box sx={{ width: "90%", overflow: "hidden" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Title
                    variant={"h6"}
                    component={"h3"}
                    sx={{ fontSize: "1rem", marginBottom: "2px" }}
                  >
                    {shortTitleChannel + " "}
                  </Title>
                  <Typography
                    variant={"caption"}
                    component={"span"}
                    sx={{ opacity: 0.7, fontSize: "0.8rem", textWrap: "nowrap" }}
                  >
                    {dateStr}
                  </Typography>
                </Box>
                <DescPost>{rawHTML}</DescPost>
              </Box>
            </ListPosts>
          </ButtonBase>
          <Divider variant="inset" component="li" />
        </Box>
      );
    });
  }, [posts]);

  return (
    <Box>
      <List sx={{ width: "100%", bgcolor: "background.paper" }}>
        {postsMarkup}
      </List>
      <ScrollPagination
        isEnd={isEnd}
        loading={loading}
        titleOfEnd={"посты закончились"}
      />
    </Box>
  );
}
