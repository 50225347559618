export const getDesignTokens = (mode) => ({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          overflow: "hidden",
          height: "100vh",
          // Другие стили для body
        },
        "#root": {
          height: "100%",
          overflow: "auto",
        },
      },
    },
  },
  typography: {
    color: "black",
  },
  palette: {
    mode,
    primary: {
      main: "#007AFE",
      grey: "#eaeaea",
    },
    secondary: {
      dark: "#000000",
      main: "#eaeaea",
      light: "#ffffff",
    },
    ...(mode === "light"
      ? {
          background: {
            default: "#ffffff",
            paper: "#ffffff",
          },
          secondary: {
            main: "#eaeaea",
            light: "#ffffff",
          },
          text: {
            primary: "#000000",
          },
        }
      : {
          background: {
            default: "#000000",
            paper: "#000000",
          },
          secondary: {
            main: "#000000",
            light: "#ffffff",
          },
          text: {
            primary: "#ffffff",
          },
        }),
  },
});
