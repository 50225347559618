import React from "react";
import StickyHeader from "./Common/StickyHeader";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import {
  $status,
  $statusPost,
  $subscriptions,
  fetchChannels,
  fetchPostChannels,
} from "../redux/slices/channelsSlice";
import { useTheme } from "@mui/material/styles";

export default function PostHeader({ hidden, channel, date }) {
  const subscriptions = useSelector($subscriptions);
  const status = useSelector($status);
  const statusPost = useSelector($statusPost);
  const dispatch = useDispatch();
  const theme = useTheme();
  const loading = status === "loading" || statusPost === "loading" || !status;
  const page = "post";
  const isSub =
    subscriptions && subscriptions.find((item) => item.id === channel.id);

  React.useEffect(() => {
    if (subscriptions.length === 0) {
      dispatch(fetchChannels(page));
    }
  }, []);

  const onClickChangeSubs = () => {
    let newSubs = subscriptions.map((sub) => sub.id);
    if (isSub) {
      newSubs = newSubs.filter((id) => id !== channel.id);
    } else {
      newSubs.push(channel.id);
    }
    dispatch(fetchPostChannels(newSubs));
  };

  const loadingButton = (
    <LoadingButton
      size={"small"}
      loading={loading}
      onClick={onClickChangeSubs}
      sx={{
        width: "110px",
        padding: 0,
        height: "fit-content",
        fontSize: "11px",
        color: isSub ? "grey" : theme.palette.primary.main,
        whiteSpace: "nowrap",
      }}
    >
      {isSub ? "Вы подписаны" : "Подписаться"}
    </LoadingButton>
  );

  return (
    <StickyHeader hidden={hidden} heightMenu={70}>
      <Box
        padding={"16px"}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        width={"-webkit-fill-available"}
      >
        <CardHeader
          avatar={<Avatar src={channel.image_100} />}
          title={channel.title}
          subheader={date}
          sx={{
            padding: 0,
            "& .MuiCardHeader-title": {
              WebkitLineClamp: "1",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "100%",
            },
          }}
        ></CardHeader>
        {loadingButton}
      </Box>
    </StickyHeader>
  );
}
