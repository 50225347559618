import React from "react";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";

export default function PopupImage({ img, onClose }) {
  return (
    <Box
      sx={{
        position: "fixed",
        height: "100vh",
        width: "100vw",
        background: "rgba(0, 0, 0, 0.9)",
        zIndex: "100",
        top: 0,
        display: "flex",
        alignItems: "center",
      }}
      onClick={onClose}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-end",
          flexDirection: "column",
        }}
      >
        <CloseIcon sx={{ fontSize: 40 }} />
        <img
          onClick={(e) => e.stopPropagation()}
          src={img}
          style={{ width: "100vw", marginBottom: "60px" }}
        />
      </Box>
    </Box>
  );
}
