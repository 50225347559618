import { postConstants } from '../constants'

const initialState = {
  post: {},
  loaded: false
}

export default function postReducer(state = initialState, action) {
  switch(action.type) {
    case postConstants.GET_POST:
      return { ...initialState, post: action.payload || {}, loaded: true }
    default:
      return initialState
  }
}
