import React from "react";
import Loader from "./Common/Loader";
import Error from "./Common/Error";

function StatusResponse({ status, children }) {
  if (status === "loading") {
    return <Loader />;
  }
  if (status === "success") {
    return children;
  }

  return <Error />;
}

export default StatusResponse;
