import React from "react";
import styled from "styled-components";
import CircularProgress from "@mui/material/CircularProgress";

const Spinner = styled(CircularProgress)(() => ({
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  margin: "auto",
}));

const Container = styled("div")(() => ({
  height: "100%",
  width: "100%",
}));

export default function Loader() {
  return (
    <Container>
      <Spinner />
    </Container>
  );
}
