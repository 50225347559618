import { combineReducers } from "@reduxjs/toolkit";

import user from './user.reducers'
import post from './post.reducers'

const rootReducer = combineReducers({
  user,
  post
})

export default rootReducer
