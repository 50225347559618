import React from "react";
import { filterChannels } from "../../redux/slices/channelsSlice";
import { debounce } from "lodash";
import Search from "../Search";
import { useDispatch } from "react-redux";
import StickyHeader from "./StickyHeader";
import useTopOfRoot from "../../hooks/useTopOfRoot";

export default function SearchChannels({ channels }) {
  const [value, setValue] = React.useState("");
  const dispatch = useDispatch();

  useTopOfRoot([value]);

  const searchChannelsCallback = React.useCallback(
    debounce((searchValue, channels) => {
      dispatch(
        filterChannels({ searchValue: searchValue, channels: channels })
      );
    }, 400),
    []
  );

  React.useEffect(() => {
    dispatch(filterChannels({ searchValue: value, channels: channels }));
  }, [channels]);

  const onChangeSearch = (e) => {
    setValue(e.target.value);
    searchChannelsCallback(e.target.value, channels);
  };

  const onClickClear = () => {
    setValue("");
    searchChannelsCallback("", channels);
  };

  return (
    <StickyHeader withoutBackground={true}>
      <Search value={value} onChange={onChangeSearch} onClear={onClickClear} />
    </StickyHeader>
  );
}
