import React from "react";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

const FixedBox = styled(Box)(() => ({
  width: "100%",
  position: "fixed",
  zIndex: "100",
  bottom: 40,
  left: 0,
  right: 0,
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-end",
}));

export default function FixedBottomBlock({ children }) {
  return <FixedBox>{children}</FixedBox>;
}
