import React from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import useDark from "../../hooks/useDark";

const TopMenuGroup = styled(Box)(({ theme, attributes }) => ({
  backdropFilter: attributes.withoutBackground ? "none" : "blur(10px)",
  boxShadow: attributes.withoutBackground
    ? "none"
    : "0px 1px 3px -2px rgba(0,0,0,0.1), 0px 1px 4px 0px rgba(0,0,0,0.14), 0px 2px 8px 0px rgba(0,0,0,0.1)",
  background: attributes.withoutBackground
    ? "none"
    : attributes.dark
    ? "rgb(0,0,0, 0.4)"
    : "rgb(250,250,250, 0.5)",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  borderRadius: "0px",
  position: "sticky",
  height: `${attributes.heightMenu}px`,
  transition: "top 300ms",
  willChange: "top",
  zIndex: 98,
  top: `${attributes.hidden ? -attributes.heightMenu : 0}px`,
}));

export default function StickyHeader({
  heightMenu,
  children,
  hidden,
  withoutBackground,
}) {
  const dark = useDark();
  return (
    <>
      <TopMenuGroup
        color="primary"
        aria-label="Platform"
        attributes={{ heightMenu, hidden, withoutBackground, dark }}
      >
        {children}
      </TopMenuGroup>
    </>
  );
}
