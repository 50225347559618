import React from "react";
import { InputAdornment, TextField } from "@mui/material";
import { Close, Search } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import useDark from "../hooks/useDark";

const SearchField = styled(TextField)(({ theme, attributes }) => ({
  width: "100%",
  margin: "4px 0",
  padding: "8px 0",
  background: "none",
  input: {
    padding: "12px 7px",
  },
  "& .MuiInputBase-root": {
    boxShadow:
      "0px 1px 3px -2px rgba(0,0,0,0.1), 0px 1px 4px 0px rgba(0,0,0,0.14), 0px 2px 8px 0px rgba(0,0,0,0.1)",
    backdropFilter: "blur(20px)",
    background: attributes.dark ? "rgb(0,0,0, 0.4)" : "rgb(250,250,250, 0.5)",
  },
  "& fieldset": {
    border: attributes.dark
      ? `0.5px solid rgb(250,250,250, 0.5)`
      : "0.5px solid rgb(250,250,250, 0)",
  },
}));

function SearchComp({ value, onChange, onClear }) {
  const dark = useDark();

  return (
    <SearchField
      id="outlined-basic"
      placeholder={"Поиск"}
      autoComplete="off"
      variant="outlined"
      value={value}
      onChange={(e) => onChange(e)}
      attributes={{ dark }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment onClick={() => onClear("")} position={"end"}>
            {value.length > 0 && <Close />}
          </InputAdornment>
        ),
      }}
    />
  );
}

export default SearchComp;
