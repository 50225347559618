import React from "react";
import Typography from "@mui/material/Typography";
import { Box, Container, MobileStepper } from "@mui/material";
import { styled } from "@mui/material/styles";

const ContainerOnBoardingPageOne = styled(Container)(() => ({
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const Paragraph = styled(Typography)(() => ({
  margin: "20px 0",
  fontSize: "16px",
}));

function OnBoardingPageOne() {
  return (
    <ContainerOnBoardingPageOne>
      <Box width={"80%"}>
        <Typography variant={"h4"} component={"h1"}>
          Как работает ранжирование?
        </Typography>
        <Paragraph variant={"caption"} component={"p"}>
          Мы используем множество критериев для оценки каждого поста, такие как
          количество просмотров, лайков, пересылок поста, подписок на канал,
          среднего ER и другие
        </Paragraph>
        <Paragraph variant={"caption"} component={"p"}>
          Используя эти данные, посту выставляется оценка и он выводится в топ,
          если его показатели лучше других
        </Paragraph>
      </Box>
    </ContainerOnBoardingPageOne>
  );
}

export default OnBoardingPageOne;
