import React from "react";
import ReactDOM from "react-dom/client";
import axios from "axios";
import store from "./redux/store";
import { Provider } from "react-redux";
import App from "./App";

const TARGET_ENV = `${process.env.TARGET_ENV}`;

let API_HOST;
switch(TARGET_ENV) {
  case "staging":
    API_HOST = "https://stage1mazafaka.smartfeedbot.ru";
    break;
  case "production":
    API_HOST = "https://smartfeedbot.ru";
    break;
  default:
    API_HOST = "http://0.0.0.0:3000";
    break;
}

const API_URL = `${API_HOST}/client/api/v1/`;

const userToken = localStorage.getItem("token");
const CSRFToken = document.querySelector('meta[name="csrf-token"]').content;

axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Authorization"] = userToken;
axios.defaults.headers.common["X-CSRF-Token"] = CSRFToken;

axios.defaults.baseURL = API_URL;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
