import React from "react";
import { Typography } from "@mui/material";
import styled from "styled-components";
import ButtonComp from "../ButtonComp";
import { useLocation, useNavigate } from "react-router-dom";

const Text = styled("div")(() => ({
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  margin: "auto",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  padding: "0 16px",
}));

const Container = styled("div")(() => ({
  height: "100%",
  width: "100%",
}));

function Error() {
  const navigate = useNavigate();

  const onClickReloadPage = () => {
    navigate("/");
  };

  return (
    <Container>
      <Text>
        <Typography variant={"caption"} sx={{ fontSize: "18px" }}>
          Возникли технические проблемы, но мы уже их чиним
        </Typography>
        <ButtonComp
          onClick={onClickReloadPage}
          name={"На главную"}
          style={{ fontSize: "12px" }}
        />
      </Text>
    </Container>
  );
}

export default Error;
