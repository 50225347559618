import React from "react";
import {functions} from "../functions";


export default function usePeriod(from, to) {
    const differenceDate = functions.getDifferenceBetweenDates(from, to)
    const {getDay} = functions
    const week = 6
    const day = 0
    const today = "сегодня"

    if (from && to) {
        if(differenceDate === week) {
            return "неделю"
        } else if(differenceDate === day && getDay(from) === today) {
            return "сутки"
        } else if(differenceDate === day) {
            return getDay(from, true)
        } else return  getDay(from, true) + " - " + functions.getDay(to, true)
    }
}