import React, { Component } from 'react'

class NotFoundPage extends Component {
  render() {
    return (
      <h1>Not found page</h1>
    )
  }
}

export default (NotFoundPage)
