import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Avatar from "@mui/material/Avatar";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import { functions } from "../functions";
import ImagePost from "./ImagePost";
import LoadingButton from "@mui/lab/LoadingButton";

export default function Post({ post, loaded, onClickShowPopup, heightMenu }) {
  const [loadingImg, setLoadingImg] = React.useState(true);
  const { channel, post_medias } = post;
  const postRef = React.useRef();
  const headerRef = React.useRef(null);

  const theme = useTheme();
  const themeName = theme.palette.mode;

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  let cardHtml;
  if (loaded && post.id) {
    let rawHTML = post.text.replaceAll("\n", "<br>");
    rawHTML = functions.getHTMLWithShortLinks(rawHTML, 8);
    const media = post_medias[0];

    let mediaHtml;
    if (media) {
      mediaHtml = (
        <ImagePost
          onLoad={() => setLoadingImg(false)}
          height={"350px"}
          img={media.file_url}
          loadingImg={loadingImg}
          onClick={() => onClickShowPopup()}
        />
      );
    }
    let date = new Date(post.date);
    date = date.toLocaleDateString("RU-ru");

    const minCardHeight = windowSize.height - heightMenu;

    const onClickLink = (e) => {
      e.preventDefault();
      (e.target.href && window.Telegram.WebApp.openLink(e.target.href)) ||
        (e.target.parentNode.tagName === "A" &&
          window.Telegram.WebApp.openLink(e.target.parentNode.href));
    };

    cardHtml = (
      <Card
        sx={{
          boxShadow: "none",
          paddingBottom: "120px",
          overflow: "initial",
          minHeight: `${minCardHeight}px`,
        }}
      >
        {mediaHtml}
        <CardContent>
          {
            <div
              ref={postRef}
              className={`cardText ${themeName}`}
              dangerouslySetInnerHTML={{ __html: rawHTML }}
              onClick={(e) => onClickLink(e)}
            />
          }
        </CardContent>
      </Card>
    );
  } else {
    cardHtml = <Box>Ты слишком активный, больше нечего читать</Box>;
  }

  return <>{cardHtml}</>;
}
