import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeSubscriptions,
  fetchChannels,
} from "../../redux/slices/channelsSlice";

export default function GetChannels({ page, children }) {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchChannels(page));
  }, []);

  return children;
}
