import * as React from "react";
import Paper from "@mui/material/Paper";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import FeedIcon from "@mui/icons-material/Feed";
import BookmarksIcon from "@mui/icons-material/Bookmarks";
import { useLocation, useNavigate, Outlet } from "react-router-dom";
import { resetData } from "../../redux/slices/postsSlice";
import { useDispatch } from "react-redux";
import { postsConstants } from "../../constants/posts.constants";
import useDark from "../../hooks/useDark";

export default function BottomMenu() {
  const [value, setValue] = React.useState("feed");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const dark = useDark();

  const { FEED_PAGES, BOOKMARKS_PAGES, HOME_PAGE, BOOKMARKS_PAGE } =
    postsConstants;
  const isIncludePage =
    !location.pathname.includes("onboarding") &&
    !location.pathname.includes("settings");

  React.useEffect(() => {
    if (BOOKMARKS_PAGES.find((item) => location.pathname.includes(item))) {
      setValue("bookmarks");
    } else if (FEED_PAGES.find((item) => location.pathname.includes(item))) {
      setValue("feed");
    }
  }, []);

  const onClickResetPostSlice = (e, page) => {
    navigate(page);
    dispatch(resetData());
  };

  return (
    <>
      <Outlet />
      <Paper
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 100,
          backdropFilter: "blur(10px)",
          background: dark ? "rgb(0,0,0, 0.4)" : "rgb(250,250,250, 0.5)",
        }}
        elevation={3}
      >
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          sx={{ background: "none" }}
        >
          <BottomNavigationAction
            onClick={(e) => onClickResetPostSlice(e, HOME_PAGE)}
            disabled={FEED_PAGES.includes(location.pathname)}
            value="feed"
            label="Лента"
            icon={<FeedIcon />}
            sx={{ maxWidth: "100%" }}
          />
          <BottomNavigationAction
            onClick={(e) => onClickResetPostSlice(e, BOOKMARKS_PAGE)}
            disabled={BOOKMARKS_PAGES.includes(location.pathname)}
            value="bookmarks"
            label="Сохраненки"
            icon={<BookmarksIcon />}
            sx={{ maxWidth: "100%" }}
          />
        </BottomNavigation>
      </Paper>
    </>
  );
}
