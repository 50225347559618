import axios from "axios";

export function getChannels() {
  return axios.get("channels");
}

export function getSubscriptions() {
  return axios.get("users/channels");
}

export function postSubscriptions(params) {
  return axios.post("users/channels", params);
}
