import React from "react";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import StatusResponse from "../components/StatusResponse";
import Channels from "../components/Common/Channels";
import { useDispatch, useSelector } from "react-redux";
import {
  $channels,
  $filteredChannels,
  $status,
  $subscriptions,
  changeSubscriptions,
} from "../redux/slices/channelsSlice";
import FixedBottomBlock from "../components/FixedBottomBlock";
import { useNavigate } from "react-router-dom";
import ButtonComp from "../components/ButtonComp";
import { useTheme } from "@mui/material/styles";
import { resetData } from "../redux/slices/postsSlice";
import SearchChannels from "../components/Common/SearchChannels";
import GetChannels from "../components/Common/GetChannels";
import { doneOnBoarding } from "../redux/slices/userSlice";
import { postSubscriptions } from "../api/channels.api";
import { postsConstants } from "../constants/posts.constants";
import _ from "lodash";
import useDark from "../hooks/useDark";
import SettingsFixedButton from "../components/SettingsFixedButton";

function SettingsPage() {
  // const [isChangeSub, setIsChangeSub] = React.useState(false);
  // const [firstSubs, setFirstSubs] = React.useState([]);
  const channels = useSelector($channels);
  const filteredChannels = useSelector($filteredChannels);
  // const subscriptions = useSelector($subscriptions);
  const status = useSelector($status);
  // const theme = useTheme();
  // const dark = useDark();
  // const dispatch = useDispatch();
  // const navigate = useNavigate();
  // const { HOME_PAGE } = postsConstants;

  // React.useEffect(() => {
  //   if (status === "success") {
  //     setFirstSubs(subscriptions);
  //   }
  // }, [status]);
  //
  // React.useEffect(() => {
  //   const subsIdsSort =
  //     subscriptions.length !== 0
  //       ? _.sortBy(subscriptions, (o) => o.id).map((item) => item.id)
  //       : [];
  //   const firstSubsIdsSort = _.sortBy(firstSubs, (o) => o.id).map(
  //     (item) => item.id
  //   );
  //
  //   if (_.isEqual(subsIdsSort, firstSubsIdsSort)) {
  //     setIsChangeSub(false);
  //   } else {
  //     setIsChangeSub(true);
  //   }
  // }, [subscriptions]);
  //
  // const onClickChangeSubscriptions = (chosenChannel) => {
  //   dispatch(changeSubscriptions({ chosenChannel }));
  // };
  //
  // const onClickClose = () => {
  //   navigate(HOME_PAGE);
  //   dispatch(resetData());
  // };
  //
  // const onClickSaveChannels = () => {
  //   const channelIds = subscriptions.map((item) => item.id);
  //   dispatch(doneOnBoarding());
  //   const postSubs = async () => {
  //     try {
  //       const response = await postSubscriptions({ channel_ids: channelIds });
  //       if (response.data.success) {
  //         navigate(HOME_PAGE);
  //         dispatch(resetData());
  //       }
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   };
  //   postSubs();
  // };

  return (
    <GetChannels page={"settings"}>
      <Box padding={"8px 16px"}>
        <Typography variant={"h5"} component={"h1"} textAlign={"center"}>
          Выбери каналы для подписки
        </Typography>
        <SearchChannels channels={channels} />
        <Typography
          variant={"caption"}
          sx={{
            width: "inherit",
            fontStyle: "italic",
            fontSize: "14px",
            textAlign: "center",
            display: "block",
          }}
        >
          Не нашел канал? Напиши{" "}
          <a href={"https://t.me/maksimrezunov"}>@maksimrezunov</a>
        </Typography>
        <StatusResponse status={status}>
          <Channels
            items={filteredChannels}
            // onClickChangeSubscriptions={onClickChangeSubscriptions}
          />
        </StatusResponse>
        <FixedBottomBlock>
          <SettingsFixedButton status={status}/>
          {/*{!isChangeSub ? (*/}
          {/*  <ButtonComp*/}
          {/*    name={"Закрыть"}*/}
          {/*    onClick={onClickClose}*/}
          {/*    style={{*/}
          {/*      color: theme.palette.text.primary,*/}
          {/*      padding: "8px 0px",*/}
          {/*      width: "130px",*/}
          {/*      backdropFilter: "blur(20px)",*/}
          {/*      boxShadow:*/}
          {/*        "0px 1px 3px -2px rgba(0,0,0,0.1), 0px 1px 4px 0px rgba(0,0,0,0.14), 0px 2px 8px 0px rgba(0,0,0,0.1)",*/}
          {/*      background: dark ? "rgb(0,0,0, 0.4)" : "rgb(250,250,250, 0.5)",*/}
          {/*      border: dark*/}
          {/*        ? `0.5px solid rgb(250,250,250, 0.5)`*/}
          {/*        : "0.5px solid rgb(250,250,250, 0)",*/}
          {/*    }}*/}
          {/*  />*/}
          {/*) : (*/}
          {/*  <ButtonComp*/}
          {/*    name={"Сохранить"}*/}
          {/*    onClick={onClickSaveChannels}*/}
          {/*    style={{*/}
          {/*      padding: "8px 0px",*/}
          {/*      width: "130px",*/}
          {/*      border: `0.5 solid ${theme.palette.primary.main}`,*/}
          {/*    }}*/}
          {/*  />*/}
          {/*)}*/}
        </FixedBottomBlock>
      </Box>
    </GetChannels>
  );
}

export default SettingsPage;
