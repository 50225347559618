import React, { useState } from "react";
import Box from "@mui/material/Box";
import WestIcon from "@mui/icons-material/West";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import LoadingButton from "@mui/lab/LoadingButton";
import BookmarkAddIcon from "@mui/icons-material/BookmarkAdd";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import { addToBookmark, removeFromBookmark } from "../api/post.api";
import { useTheme } from "@mui/material/styles";

export default function PostNavigation({ post, hidePopup }) {
  if (!post.id) return;

  const theme = useTheme();

  const [loadingBookmark, setLoadingBookmark] = useState(false);
  const [bookmarkIcon, setBookmarkIcon] = useState(
    post.bookmark ? <BookmarkAddedIcon /> : <BookmarkAddIcon />
  );
  const [bookmarkAdded, setBookmarkAdded] = useState(post.bookmark);

  const handleBookmark = () => {
    if (loadingBookmark) return;

    setLoadingBookmark(true);

    if (bookmarkAdded) {
      removeFromBookmark(post.id).then((response) => {
        setBookmarkIcon(<BookmarkAddIcon />);
        setBookmarkAdded(false);
        setLoadingBookmark(false);
      });
    } else {
      addToBookmark(post.id).then((response) => {
        setBookmarkIcon(<BookmarkAddedIcon />);
        setBookmarkAdded(true);
        setLoadingBookmark(false);
      });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        position: "fixed",
        bottom: "70px",
        left: 0,
        right: 0,
        transition: "opacity 300ms ease 0s",
        zIndex: 100,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          "& > *": {
            m: 1,
          },
        }}
      >
        <ButtonGroup
          aria-label="outlined button group"
          sx={{ background: theme.palette.background.paper }}
        >
          <Button
            variant="contained"
            loadingposition="end"
            startIcon={<WestIcon />}
            onClick={hidePopup}
          >
            Назад
          </Button>

          <LoadingButton
            variant="contained"
            loading={loadingBookmark}
            onClick={() => handleBookmark()}
          >
            {bookmarkIcon}
          </LoadingButton>
        </ButtonGroup>
      </Box>
    </Box>
  );
}
