import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getChannels,
  getSubscriptions,
  postSubscriptions,
} from "../../api/channels.api";

export const fetchChannels = createAsyncThunk(
  "channels/getChannels",
  async (page) => {
    switch (page) {
      case "settings": {
        let channels = await getChannels();
        let subscriptions = await getSubscriptions();
        return { channels, subscriptions };
        break;
      }
      case "onboarding": {
        let channels = await getChannels();
        return { channels };
        break;
      }
      case "post": {
        let subscriptions = await getSubscriptions();
        return { subscriptions };
        break;
      }
    }
  }
);

export const fetchPostChannels = createAsyncThunk(
  "channels/postChannels",
  async (channelIds) => {
    const response = await postSubscriptions({ channel_ids: channelIds });
    return response;
  }
);

const initialState = {
  channels: [],
  filteredChannels: [],
  subscriptions: [],
  status: null, //loading // success // error
  statusPost: null, //loading // success // error
  newChannelsId: [],
  newChannels: {}
};

const channelSlice = createSlice({
  name: "channels",
  initialState,
  reducers: {
    changeSubscriptions: (state, action) => {
      const { chosenChannel } = action.payload;

      //Change state subscription
      const isChosenBefore =
        state.subscriptions.find((item) => item.id === chosenChannel.id) ===
        undefined;
      const addedChannel = [...state.subscriptions, chosenChannel];
      const delChannel = state.subscriptions.filter(
        (item) => item.id !== chosenChannel.id
      );

      const result = isChosenBefore ? addedChannel : delChannel;
      state.subscriptions = result;

      //change state channels
      state.channels.map((item) => {
        item.isChosen =
          item.id === chosenChannel.id ? !item.isChosen : item.isChosen;
        return item;
      });
    },

    changeSubscriptionsNew: (state, action) => {
      const { id } = action.payload;
      const isChosenBefore = state.newChannels[id].checked;
      const addedChannel = [...state.subscriptions, state.newChannels[id]];
      const delChannel = state.subscriptions.filter(
          (item) => item.id !== state.newChannels[id].id
      );
      state.newChannels[id].checked = !state.newChannels[id].checked;
      state.subscriptions = !isChosenBefore ? addedChannel : delChannel;
    },
    filterChannels: (state, action) => {
      const { channels, searchValue } = action.payload;
      state.filteredChannels = channels.filter((item) =>
        item.title.toLowerCase().includes(searchValue.toLowerCase())
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchChannels.fulfilled, (state, action) => {
        const { channels, subscriptions } = action.payload;
        const primeChannelIds = [18, 27];
        const place = 4;

        if (channels) {
          let sortedChannels = channels.data.data.channels.filter(
            (item) => !primeChannelIds.includes(item.id)
          );
          sortedChannels = [
            ...sortedChannels.slice(0, place),
            ...channels.data.data.channels.filter((item) =>
              primeChannelIds.includes(item.id)
            ),
            ...sortedChannels.slice(place, sortedChannels.length),
          ];
          state.channels = sortedChannels.map((item) => {
            let isChosen = false;
            if (action.payload.subscriptions) {
              isChosen =
                action.payload.subscriptions.data.data.channels.find(
                  (subs) => item.id === subs.id
                ) !== undefined;
            }
            return {
              ...item,
              isChosen,
            };
          });
        }
        if(channels) {
          channels.data.data.channels.forEach((channel) => {
            state.newChannels[channel.id] = ({
              ...channel,
              checked: false
            })
          })
        }
        if(subscriptions) {
          subscriptions.data.data.channels.forEach((channel) => {
            state.newChannels[channel.id] = ({
              ...channel,
              checked: true
            })
          })
        }


        state.subscriptions = subscriptions
          ? subscriptions.data.data.channels
          : [];
        state.status = "success";
      })
      .addCase(fetchChannels.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchChannels.rejected, (state, action) => {
        state.status = "error";
      });

    builder
      .addCase(fetchPostChannels.pending, (state, action) => {
        state.statusPost = "loading";
      })
      .addCase(fetchPostChannels.fulfilled, (state, action) => {
        const subscriptions = action.payload;
        state.subscriptions = subscriptions
          ? subscriptions.data.data.channels
          : [];
        state.statusPost = "success";
      })
      .addCase(fetchPostChannels.rejected, (state, action) => {
        state.statusPost = "error";
      });
  },
});

export const { changeSubscriptions, filterChannels, changeSubscriptionsNew } = channelSlice.actions;

export default channelSlice.reducer;

export const $subscriptions = (state) => state.channels.subscriptions;
export const $status = (state) => state.channels.status;
export const $statusPost = (state) => state.channels.statusPost;
export const $channels = (state) => state.channels.channels;
export const $filteredChannels = (state) => state.channels.filteredChannels;
export const $channel = (id) => (state) => state.channels.newChannels[id]
