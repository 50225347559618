import axios from "axios";

export function getPosts(data) {
  return axios.get("posts", {
    params: data,
  });
}

export function getViewed(data) {
  return axios.get("posts/viewed", {
    params: data,
  });
}

export function showPost(postId) {
  return axios.get(`posts/${postId}`);
}

export function getBookmarks(data) {
  return axios.get("posts/bookmarks", { params: data });
}

export function addToBookmark(postId) {
  return axios.post(`posts/${postId}/add_to_bookmarks`);
}

export function removeFromBookmark(postId) {
  return axios.post(`posts/${postId}/remove_from_bookmarks`);
}

export function getBestOfDates(data) {
  return axios.get("posts/best_of_dates", {
    params: data,
  });
}
