import React, { useLayoutEffect } from "react";
import Post from "../components/Post";
import PostNavigation from "../components/PostNavigation";
import {useLocation, useNavigate} from "react-router-dom";
import Loader from "../components/Common/Loader";
import { useParams } from "react-router-dom";
import { showPost } from "../api/post.api";
import Error from "../components/Common/Error";
import Popup from "../components/Popup";
import PopupImage from "../components/PopupImage";
import useScrollToHidden from "../hooks/useScrollToHidden";
import PostHeader from "../components/PostHeader";

const heightMenu = 80;

export default function PostPage() {
  const [post, setPost] = React.useState({});
  const [isShow, setShow] = React.useState(false);
  const [loader, setLoader] = React.useState(true);
  const [err, setErr] = React.useState(false);
  const [hidden, handleScroll] = useScrollToHidden(70);
  const [showImage, setShowImage] = React.useState(false);
  let date = new Date(post.date);
  const { channel } = post;
  const location = useLocation()
  date = date.toLocaleDateString("RU-ru");
  const fullURL = location.pathname + (location.search === "?undefined" ? "" : location.search)
  const navigate = useNavigate();
  const params = useParams();


  useLayoutEffect(() => {
    showPost(params.id)
      .then((response) => {
        setPost(response.data.data.post);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        setErr(true);
      });
  }, []);

  React.useEffect(() => {
    setShow(true);
  }, []);

  const onChangeHide = () => {
    setShow(false);
    navigate(fullURL.replace("/" + params.id, ""));
  };

  const onTransitionNavigate = () => {
    if (!isShow) {
      navigate(fullURL.replace("/" + params.id, ""));
    }
  };

  let postComp = loader ? (
    <Loader />
  ) : err ? (
    <Error />
  ) : (
    <>
      <PostHeader
        date={date}
        heightMenu={heightMenu}
        channel={channel}
        hidden={hidden}
      />
      <Post
        post={post}
        loaded={true}
        heightMenu={heightMenu}
        onClickShowPopup={() => setShowImage(true)}
      />
    </>
  );

  return (
    <>
      <Popup
        show={isShow}
        onTransitionEnd={onTransitionNavigate}
        hidePopup={onChangeHide}
        onScroll={handleScroll}
      >
        {postComp}
      </Popup>
      <PostNavigation post={post} hidePopup={onChangeHide} />
      {!loader && showImage && (
        <PopupImage
          img={post.post_medias[0].file_url}
          onClose={() => setShowImage(false)}
        />
      )}
    </>
  );
}
