import React from "react";
import { styled } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import Box from "@mui/material/Box";
import Loader from "./Common/Loader";

const ImgContainer = styled(CardMedia)(({ theme, attributes }) => {
  const generalStyle = {
    height: attributes.height,
    width: "100vw",
    display: attributes.loadingImg ? "none" : "block",
  };
  if (attributes.frontImg) {
    return {
      ...generalStyle,
      objectFit: "contain",
      position: "absolute",
      zIndex: "2",
      backgroundColor: "rgba(0, 0, 0, 0)",
    };
  }
  return {
    ...generalStyle,
    objectFit: "cover",
    filter: "blur(7px)",
  };
});

const LoaderContainer = styled(Box)(({ theme, attributes }) => ({
  position: "relative",
  height: attributes.height,
  width: "100vw",
  background: theme.palette.background.default,
}));

export default function ImagePost({
  onLoad,
  loadingImg,
  height,
  img,
  onClick,
}) {
  return (
    <>
      {loadingImg && (
        <LoaderContainer attributes={{ height }}>
          <Loader />
        </LoaderContainer>
      )}
      <Box sx={{ position: "relative", height: height }} onClick={onClick}>
        <Box sx={{ position: "absolute", overflow: "hidden" }}>
          <ImgContainer
            attributes={{ frontImg: false, loadingImg, height }}
            component="img"
            image={img}
          />
        </Box>
        <ImgContainer
          attributes={{ frontImg: true, loadingImg, height }}
          onLoad={() => onLoad()}
          component="img"
          image={img}
        />
      </Box>
    </>
  );
}
