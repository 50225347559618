import React from "react";
import { useLocation, Outlet, useNavigate } from "react-router-dom";
import { Box, MobileStepper } from "@mui/material";
import ButtonComp from "../../components/ButtonComp";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { postSubscriptions } from "../../api/channels.api";
import {$onboardingPassed, doneOnBoarding} from "../../redux/slices/userSlice";
import FixedBottomBlock from "../../components/FixedBottomBlock";
import { resetData } from "../../redux/slices/postsSlice";
import OnboardingButton from "../../components/OnboardingButton";

const Pagination = styled(MobileStepper)(() => ({
  maxWidth: 400,
  margin: "0 10px",
  padding: "0",
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
  "& .MuiMobileStepper-dot": {
    margin: "0 8px",
  },
}));

const FixedBox = styled(Box)(() => ({
  width: "100%",
  position: "fixed",
  zIndex: "100",
  bottom: 40,
  left: 0,
  right: 0,
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-end",
}));

function OnBoardingPage() {
  const navigate = useNavigate();
  const location = useLocation();
  React.useEffect(() => {
    if (location.pathname === "/onboarding") {
      navigate("/onboarding/step-one");
    }
  }, []);


  return (
    <>
      <Outlet />
      <FixedBottomBlock>
        <OnboardingButton />
      </FixedBottomBlock>
    </>
  );
}

export default OnBoardingPage;
