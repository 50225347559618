import React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

const PopupContainer = styled(Box)(({ theme }) => ({
  width: "100vw",
  height: "100%",
  overflowY: "auto",
  zIndex: 99,
  position: "fixed",
  top: 0,
  background: theme.palette.background.default,
}));

export default function Popup({
  children,
  show,
  hidePopup,
  onTransitionEnd,
  onScroll,
}) {
  return (
    <PopupContainer
      onTransitionEnd={onTransitionEnd}
      sx={{ opacity: show ? "1" : "0" }}
      onScroll={onScroll}
    >
      {children}
    </PopupContainer>
  );
}
