import React from "react";
import { Avatar, Checkbox, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Loader from "./Loader";
import Channel from "./Channel";

export let Item = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
}));

export const CheckboxCircle = styled(Checkbox)(() => ({
  position: "relative",
  width: "110px",
  height: "110px",
}));

export const ChannelImg = styled(Avatar)(({ theme }) => ({
  width: "110px",
  height: "110px",
  borderRadius: "60px",
  border: `3px solid ${theme.palette.secondary.main}`,
  boxShadow: `0 0 0 4px ${theme.palette.background.default}`,
}));

export const NameChannel = styled(Typography)(() => ({
  marginTop: "8px",
  textAlign: "center",
  fontSize: "14px",
  display: "-webkit-box",
  lineHeight: 1.4,
  WebkitLineClamp: "2",
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis",
}));

export const ChannelImgChecked = styled(ChannelImg)(({ theme }) => ({
  border: `3px solid ${theme.palette.secondary.main}`,
  outline: "none",
  borderRadius: "60px" /* значение радиуса скругления */,
  boxShadow: `0 0 0 4px ${theme.palette.primary.main}`,
}));

function Channels({ items, onClickChangeSubscriptions, searchValue }) {
  const [visibleImages, setVisibleImages] = React.useState([]);
  const imagesRef = React.useRef([]);

  React.useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const newVisibleImages = entries
        .filter((entry) => entry.isIntersecting)
        .map((entry) => entry.target.getAttribute("data-src"));
      setVisibleImages((visibleImages) => [
        ...visibleImages,
        ...newVisibleImages,
      ]);
    });
    imagesRef.current.forEach(
      (image) => image !== null && observer.observe(image)
    );
    return () => {
      observer.disconnect();
    };
  }, [items]);

  return (
    <Grid container sx={{ mt: "8px" }} spacing={3} columns={{ xs: 3 }}>
      {items.map((item, i) => (
          <Channel
              key={item.id}
              id={item.id}
              visibleImages={visibleImages}
              refs={(el) => (imagesRef.current[i] = el)}
          />
      ))}
      {/*{items.map((item, i) => (*/}
      {/*  <Grid item xs={1} key={item.id}>*/}
      {/*    <Item>*/}
      {/*      <CheckboxCircle*/}
      {/*        checked={item.isChosen}*/}
      {/*        ref={(el) => (imagesRef.current[i] = el)}*/}
      {/*        onClick={() => onClickChangeSubscriptions(item)}*/}
      {/*        data-src={item["image_100"]}*/}
      {/*        disableRipple*/}
      {/*        icon={*/}
      {/*          visibleImages.includes(item["image_100"]) ? (*/}
      {/*            <ChannelImg alt={item.title} src={item["image_100"]} />*/}
      {/*          ) : (*/}
      {/*            <Loader />*/}
      {/*          )*/}
      {/*        }*/}
      {/*        checkedIcon={*/}
      {/*          visibleImages.includes(item["image_100"]) ? (*/}
      {/*            <ChannelImgChecked alt={item.title} src={item["image_100"]} />*/}
      {/*          ) : (*/}
      {/*            <Loader />*/}
      {/*          )*/}
      {/*        }*/}
      {/*      />*/}
      {/*      <NameChannel variant={"caption"}>{item.title}</NameChannel>*/}
      {/*    </Item>*/}
      {/*  </Grid>*/}
      {/*))}*/}
    </Grid>
  );
}

export default Channels;
