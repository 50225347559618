import React from "react";
import Loader from "./Common/Loader";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

const Wrapper = styled(Box)(() => ({
  position: "relative",
  display: "flex",
  height: "60px",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  "& > *": {
    m: 1,
  },
}));

export default React.memo(function ScrollPagination({
  loading,
  isEnd,
  titleOfEnd,
}) {
  if (loading) {
    return (
      <Wrapper>
        <Loader />
      </Wrapper>
    );
  }

  if (isEnd) {
    return (
      <Wrapper>
        <Typography variant={"caption"} sx={{ fontSize: "14px", opacity: 0.8 }}>
          {titleOfEnd}
        </Typography>
      </Wrapper>
    );
  }
  return null;
});
