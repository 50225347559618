import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {auth, onboardingPass} from "../../api/user.api";
import axios from "axios";

export const fetchUser = createAsyncThunk("users/auth", async (userData) => {
  const response = await auth({ data: userData });
  return response;
});

export const fetchOnboardingPass = createAsyncThunk("users/onboardingPass", async() => {
  const response = await onboardingPass()
  return response;
})

const initialState = {
  user: {},
  onboarding_passed: null,
  token: "",
  status: null, //loading // success // error
  statusOnboardingPass: "loading" //loading // success // error
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    doneOnBoarding: (state) => {
      state.onboarding_passed = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUser.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(fetchUser.fulfilled, (state, action) => {
      const { user, token } = action.payload.data.data;
      state.user = user;
      state.token = token;
      state.onboarding_passed = user.onboarding_passed;
      localStorage.setItem("token", token);
      axios.defaults.headers.common["Authorization"] = token;
      state.status = "success";
    });
    builder.addCase(fetchUser.rejected, (state, action) => {
      state.status = "error";
    });
    builder.addCase(fetchOnboardingPass.pending, (state) => {
      state.statusOnboardingPass = "loading"
    })
    builder.addCase(fetchOnboardingPass.fulfilled, (state) => {
      state.statusOnboardingPass = "success"
      state.onboarding_passed = true
    })
    builder.addCase(fetchOnboardingPass.rejected, (state) => {
      state.statusOnboardingPass = "error"
    })
  },
});

export const { doneOnBoarding } = userSlice.actions;

export default userSlice.reducer;

export const $user = (state) => state.user.user;
export const $onboardingPassed = (state) => state.user.onboarding_passed;
