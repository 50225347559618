import React from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import {  useSelector } from "react-redux";
import {$onboardingPassed} from "../redux/slices/userSlice";
import Loader from "./Common/Loader";

function OnboardingCheck() {
  const location = useLocation();
  const mainPage = location.pathname === "/";
  const onboarding_passed = useSelector($onboardingPassed)

  if (onboarding_passed === null) {
    return <Loader/>
  }

  return !onboarding_passed ?
      <Navigate to={"/onboarding"}/>
      : mainPage ? (
      <Navigate to={"/subscriptions"} />
  ) : (
      <Outlet />
  )
}

export default OnboardingCheck;
