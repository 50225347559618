import React from "react";
import TuneIcon from "@mui/icons-material/Tune";
import { styled } from "@mui/material/styles";
import { IconButton } from "@mui/material";

const SettingsButtonComp = styled(IconButton)(({ theme }) => ({
  background: theme.palette.primary.main,
  borderRadius: "7px",
  padding: "2px 6px",
  marginLeft: "10px",
  "&:hover": {
    background: theme.palette.primary.main,
    opacity: 0.8,
  },
}));

export default function SettingsButton({ onClick }) {
  return (
    <SettingsButtonComp component={"div"} onClick={(e) => onClick(e)}>
      <TuneIcon
        sx={{
          transform: "rotate(90deg)",
          fontSize: "15px",
          color: "white",
        }}
      />
    </SettingsButtonComp>
  );
}
