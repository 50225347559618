import React from "react";
import { Routes, Route } from "react-router-dom";
import NotFoundPage from "./pages/NotFoundPage";
import BottomMenu from "./components/Common/BottomMenu";
import PostPage from "./pages/PostPage";
import OnBoardingPage from "./pages/onBoardingPages/OnBoardingPage";
import OnBoardingPageOne from "./pages/onBoardingPages/OnBoardingPageOne";
import OnBoardingPageTwo from "./pages/onBoardingPages/OnBoardingPageTwo";
import PostsPage from "./pages/PostsPage";
import SettingsPage from "./pages/SettingsPage";
import OnboardingCheck from "./components/OnboardingCheck";
import {useSelector} from "react-redux";
import {$onboardingPassed} from "./redux/slices/userSlice";

const routesListOfPosts = [
  {
    path: "/subscriptions",
    title: "Твоя Лента",
    emptyMessage: "Подпишись на свои каналы для отслеживания",
  },
  {
    path: "/posts",
    title: "Все каналы",
    emptyMessage: "Пока нечего читать",
  },
  {
    path: "/bookmarks",
    title: "Сохраненки",
    emptyMessage: "Пока что нет сохраненных записей",
  },
  {
    path: "/viewed",
    title: "Просмотренные",
    emptyMessage: "Пока что нет просмотренных постов",
  },
];

export default () => {
  return (
    <>
      <Routes>
        <Route element={<OnboardingCheck />}>
          <Route element={<BottomMenu />}>
            {routesListOfPosts.map((route, i) => (
              <Route
                key={i}
                path={route.path}
                element={
                  <PostsPage
                    title={route.title}
                    emptyMessage={route.emptyMessage}
                  />
                }
              >
                <Route path={`${route.path}/:id`} element={<PostPage />} />
              </Route>
            ))}
            <Route path="/posts/:postId" element={<PostPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Route>
          <Route path={"/settings"} element={<SettingsPage />} />
        </Route>
        <Route path={"/best-of-dates/*"} element={ <PostsPage title={"Лучшее за"}  emptyMessage={"Пока что нет лучших постов"}/>}>
          <Route path={`:id`} element={<PostPage />} />
          </Route>
        <Route path="/onboarding/*" element={<OnBoardingPage />}>
          <Route path={"step-one"} element={<OnBoardingPageOne />} />
          <Route path={"step-two"} element={<OnBoardingPageTwo />} />
        </Route>
      </Routes>
    </>
  );
};
