import React from "react";
import { ToggleButton, Box, ToggleButtonGroup } from "@mui/material";
import { styled } from "@mui/material/styles";
import { shallowEqual, useDispatch } from "react-redux";
import { resetData } from "../../redux/slices/postsSlice";
import { useLocation, useNavigate } from "react-router-dom";
import SettingsButton from "../SettingsButton";
import useScrollToHidden from "../../hooks/useScrollToHidden";
import StickyHeader from "./StickyHeader";

const heightMenu = 50;
const borderHeight = 2;

const ButtonOfMenu = styled(ToggleButton)(({ theme }) => {
  return {
    width: "inherit",
    border: "none",
    borderRadius: "0px",
    borderBottom: `${borderHeight}px solid ${theme.palette.primary.grey}`,
    position: "relative",
    color: theme.palette.text.primary,
    textTransform: "capitalize",
    fontSize: "16px",
    transform: "translateZ(0)",
    "&:disabled": {
      border: "none",
      borderBottom: `${borderHeight}px solid ${theme.palette.primary.grey}`,
      opacity: 1,
      color: theme.palette.text.primary,
    },
    "&:after": {
      position: "fixed",
      bottom: -borderHeight,
      height: `${borderHeight}px`,
      background: theme.palette.primary.main,
      width: 0,
      content: '""',
      transition: "width 200ms",
    },
    "&.active": {
      zIndex: 100,
      "&:after": {
        width: "100%",
      },
    },
  };
});

export default function TopMenu({ menu, showSettings, location }) {
  const hidden = useScrollToHidden(100, true);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onClickMenu = (link) => {
    if (link !== location.pathname) {
      navigate(link);
      dispatch(resetData());
    }
  };

  const onClickSettings = (e) => {
    navigate("/settings");
    e.stopPropagation();
  };

  return (
    <StickyHeader hidden={hidden} heightMenu={heightMenu + borderHeight}>
      <ToggleButtonGroup
        color="primary"
        aria-label="Platform"
        sx={{ width: "-webkit-fill-available" }}
      >
        w
        {menu.map((item, i) => (
          <ButtonOfMenu
            key={i}
            onClick={() => onClickMenu(item.link)}
            className={location.pathname.includes(item.link) ? "active" : ""}
            value="web"
          >
            {item.title}
            {i === 0 && showSettings && (
              <SettingsButton onClick={onClickSettings} />
            )}
          </ButtonOfMenu>
        ))}
      </ToggleButtonGroup>
    </StickyHeader>
  );
}
