import React, { useLayoutEffect } from "react";
import Routes from "./routes";
import GlobalStyles from "@mui/material/GlobalStyles";
import CssBaseline from "@mui/material/CssBaseline";
import useMediaQuery from "@mui/material/useMediaQuery";
import Loader from "./components/Common/Loader";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { getDesignTokens } from "./getDesignTokens";
import { BrowserRouter } from "react-router-dom";
import { fetchUser } from "./redux/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";

export default function App() {
  const { status } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const tg = window.Telegram.WebApp;
  let userData = tg.initData;

  tg.expand();

  /* webpack-strip-block:removed */

  useLayoutEffect(() => {
    dispatch(fetchUser(userData));
  }, []);

  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const mode = prefersDarkMode ? "dark" : "light";

  const theme = React.useMemo(
    () => createTheme(getDesignTokens(mode)),
    [prefersDarkMode]
  );

  let mainContainer;
  if (status === "loading") {
    mainContainer = <Loader />;
  } else {
    mainContainer = (
      <>
        <Routes />
      </>
    );
  }

  return (
    <React.Fragment>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <GlobalStyles
            styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
          />
          <CssBaseline />
          {mainContainer}
        </ThemeProvider>
      </BrowserRouter>
    </React.Fragment>
  );
}
