import Loader from "./Loader";
import {Grid} from "@mui/material";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {$channel, changeSubscriptionsNew} from "../../redux/slices/channelsSlice";
import {ChannelImg, ChannelImgChecked, CheckboxCircle, Item, NameChannel} from "./Channels";
import {Skeleton} from "@mui/material";
import Box from "@mui/material/Box";


export default function Channel({id, visibleImages, refs}) {
    const channel = useSelector($channel(id))
    const dispatch = useDispatch()

    return (
        <Grid item xs={1} key={channel.id}>
            <Item>
                <CheckboxCircle
                    checked={channel.checked}
                    ref={refs}
                    onClick={() => dispatch(changeSubscriptionsNew({id}))}
                    data-src={id}
                    disableRipple
                    icon={
                        visibleImages.includes(id.toString()) ? (
                            <ChannelImg alt={channel.title} src={channel["image_100"]} />
                        ) : (
                            <Box width={"110px"} height={"110px"}>
                                <Skeleton variant="circular" width={"110px"} height={"110px"} />
                            </Box>
                        )
                    }
                    checkedIcon={
                        visibleImages.includes(id.toString()) ? (
                            <ChannelImgChecked alt={channel.title} src={channel["image_100"]} />
                        ) : (
                            <Box width={"110px"} height={"110px"}>
                                <Skeleton variant="circular" width={"110px"} height={"110px"} />
                            </Box>
                        )
                    }
                />
                <NameChannel variant={"caption"}>{channel.title}</NameChannel>
            </Item>
        </Grid>
    )
}