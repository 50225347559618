import { regexConstants } from "./constants/regex.constants";

export const functions = {
  getDay: (date, full) => {
    const newDate = new Date(date)
    const months = full ? [
        "января",
            "февраля",
            "марта",
            "апреля",
            "мая",
            "июня",
            "июля",
            "августа",
            "сентября",
            "октября",
            "ноября",
            "декабря",
        ] :
        [
      "янв",
      "фев",
      "мар",
      "апр",
      "мая",
      "июн",
      "июл",
      "авг",
      "сен",
      "окт",
      "ноя",
      "дек",
    ]
    const yesterday = new Date(Date.now() - 86400000)
    const today = new Date(Date.now())
      const timeDiff = Math.abs(today.getTime() - newDate.getTime());
      const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
      const week = Math.ceil((diffDays - 6) / 7)
    const dateStr = full ? `${newDate.getDate()} ${months[newDate.getMonth()]}`
        : yesterday.setHours(0, 0, 0, 0) === newDate.setHours(0, 0, 0, 0)
        ? "вчера"
        : today.setHours(0, 0, 0, 0) === newDate.setHours(0, 0, 0, 0)
        ? "сегодня"
        : diffDays <= 6
        ? diffDays + " дн"
        : diffDays > 6 && diffDays < 20
        ? week + " нед"
        : `${newDate.getDate()} ${months[newDate.getMonth()]}`;

    return dateStr;
  },
  getShortLink: (link, lengthPath) => {
    const parseURL = new URL(link);
    const pathName =
      parseURL.pathname.length > lengthPath
        ? parseURL.pathname.substring(0, lengthPath) + "..."
        : parseURL.pathname;
    const hash =
      pathName.length > 1
        ? ""
        : parseURL.hash.length > lengthPath
        ? parseURL.hash.substring(0, lengthPath) + "..."
        : parseURL.hash;
    const shortUrl = parseURL.origin + pathName + hash;
    return shortUrl;
  },
  getHTMLWithShortLinks: (htmlText, lengthPath) => {
    let newHTML = htmlText;

    const aTags = [...newHTML.matchAll(regexConstants.LINKS_REGEX)];

    const links = aTags
      .map((match) => match[1])
      .filter((item) => item.match(regexConstants.URL_REGEX))
      .map((item) => item.match(regexConstants.URL_REGEX)[0]);

    const shortLink = links.map((item) =>
      functions.getShortLink(item, lengthPath)
    );

    shortLink.forEach((item, i) => {
      newHTML = newHTML.replace(">" + links[i] + "<", ">" + item + "<");
    });
    return newHTML;
  },
  getDifferenceBetweenDates: (dateOne, dateTwo) => {
    const oneDate = new Date(dateOne)
    const twoDate = new Date(dateTwo)
    const timeDiff = Math.abs(twoDate.getTime() - oneDate.getTime());
    const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

    return diffDays
  }
};
