import React from "react";
import Typography from "@mui/material/Typography";
import styled from "styled-components";

const Content = styled("div")(() => ({
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  margin: "auto",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const Container = styled("div")(() => ({
  height: "100%",
  width: "100%",
}));

const Text = styled(Typography)(() => ({
  display: "block",
  fontSize: "18px",
  textAlign: "center",
}));

export default function EmptyPosts({ children }) {
  return (
    <Container>
      <Content>
        <Text variant={"caption"}>{children}</Text>
      </Content>
    </Container>
  );
}
