import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {getBestOfDates, getBookmarks, getPosts, getViewed} from "../../api/post.api";
import { postsConstants } from "../../constants/posts.constants";

export const fetchPosts = createAsyncThunk("post/getPosts", async (props) => {
  const { period, page, action, type, order, from, to} = props;
  const { PER } = postsConstants;
  let response;
  switch (type) {
    case "subscriptions":
      response = await getPosts({
        period,
        page,
        per: PER,
        pick: "subscriptions",
        order,
      });
      break;

    case "posts":
      response = await getPosts({ period, page, per: PER });
      break;

    case "bookmarks":
      response = await getBookmarks({ page, per: PER });
      break;

    case "viewed":
      response = await getViewed({ page, per: PER });
      break;

    case "best-of-dates":
      response = await getBestOfDates({ page, per: PER, from, to });
  }
  return {
    data: response.data,
    action: action,
  };
});

const initialState = {
  posts: [],
  firstRender: true,
  status: "loading", //loading // success // error
  isEnd: false,
  scrollY: 0,
  page: 1,
  order: null, // smart // by_date
};

const postsSlice = createSlice({
  name: "posts",
  initialState,
  reducers: {
    saveScroll: (state, action) => {
      state.scrollY = action.payload.scrollY;
    },
    readPost: (state, action) => {
      state.posts[action.payload.i].readed = true;
    },
    onChangeFirstRender: (state, action) => {
      state.firstRender = action.payload.data;
    },
    onChangeStatus: (state, action) => {
      state.status = "loading";
    },
    resetData: (state) => {
      state.posts = [];
      state.firstRender = true;
      state.status = "loading";
      state.isEnd = false;
      state.page = 1;
    },
    incPage: (state) => {
      state.page += 1;
    },
    changeOrder: (state, action) => {
      if (!action.payload) {
        if (state.order === "smart") {
          state.order = "by_date";
        } else {
          state.order = "smart";
        }
      } else {
        state.order = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPosts.fulfilled, (state, action) => {
      const postsList = state.posts;
      const newPostsList = [...state.posts, ...action.payload.data.data.posts];
      const respPostsList = action.payload.data.data.posts;

      if (state.page > 1) {
        state.isEnd =
          postsList.length === newPostsList.length ||
          respPostsList.length < postsConstants.PER;
      }

      state.posts =
        action.payload.action === "add" ? newPostsList : respPostsList;
      state.firstRender = false;
      state.status = "success";
    });
    builder.addCase(fetchPosts.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(fetchPosts.rejected, (state, action) => {
      state.status = "error";
    });
  },
});

export const {
  onChangeFirstRender,
  onChangeStatus,
  resetData,
  readPost,
  saveScroll,
  incPage,
  changeOrder,
} = postsSlice.actions;

export default postsSlice.reducer;

export const $firstRender = (state) => state.posts.firstRender;
export const $posts = (state) => state.posts.posts;
export const $status = (state) => state.posts.status;
export const $isEnd = (state) => state.posts.isEnd;
export const $scrollY = (state) => state.posts.scrollY;
export const $page = (state) => state.posts.page;
export const $order = (state) => state.posts.order;
